import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export enum UserLanguage {
  English = "en",
  Bulgarian = "bg",
}

interface UserLocaleStore {
  language: UserLanguage;
  setLanguage: (language: UserLanguage) => void;
}

const useUserLocaleStore = create<UserLocaleStore>()(
  persist(
    set => ({
      language: UserLanguage.English,
      setLanguage: language => set({ language }),
    }),
    {
      name: "@hp:user_locale",
      storage: createJSONStorage(() => localStorage),
      partialize: state => ({
        language: state.language,
      }),
      // onRehydrateStorage: state => {
      //   console.log("@hp:user_locale_store: ", state);
      // },
    },
  ),
);

export default useUserLocaleStore;
