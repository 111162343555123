import { create } from "zustand";

// @todo: typing from the @/api package
type User = {
  name: string;
  email: string;
};

interface UserStore {
  user: User | null;
  setUser: (user: User) => void;
  resetUser: () => void;
}

const useUserStore = create<UserStore>(set => ({
  user: null,
  setUser: user => set({ user }),
  resetUser: () => set({ user: null }),
}));

export default useUserStore;
