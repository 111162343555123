import "normalize.css/normalize.css";
import "./i18n";

import React from "react";
import ReactDOM from "react-dom/client";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <div />
  </React.StrictMode>,
);
