import { create } from "zustand";

// @todo: typing from the @/api package
type Notifications = unknown;

interface NotificationsStore {
  notifications: Notifications | null;
  set: (user: Notifications) => void;
  reset: () => void;
}

const useUserNotificationsStore = create<NotificationsStore>(set => ({
  notifications: null,
  set: notifications => set({ notifications }),
  reset: () => set({ notifications: null }),
}));

export default useUserNotificationsStore;
