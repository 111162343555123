import { UserLanguage } from "@hotelspoint/store";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import resources from "virtual:i18next-loader";

const parsedResources = Object.fromEntries(
  Object.keys(resources).map(key => [key, { translation: resources[key] }]),
);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: import.meta.env.DEV,
    resources: parsedResources,
    lng: UserLanguage.English,
    fallbackLng: UserLanguage.English,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
